// import "./App.css";
import Landing from "./components/landing";
import LoginPage from "./components/login/LoginPage";

function App() {
  // return <Landing/>;
  return <LoginPage />;
}

export default App;
