import React, { useState } from "react";
import { CircularProgress, Box, Alert } from '@mui/material';

import Radio from "@mui/material/Radio";
import {
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile, login } from "../../redux/features/auth/authSilice";

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [AP, setAP] = useState("asset");

  const handleAP = (event) => {
    setAP(event.target.value);
  };

  const controlAP = (item) => ({
    checked: AP === item,
    onChange: handleAP,
    value: item,
    name: "color-radio-button-demo",
    inputProps: { "aria-label": item },
  });

  const { isSuccess, isLoading, isError } = useSelector((state) => state.auth)


  let hrefValue = '';
  let hrefValueAsset = 'https://asset.ifmtnbes.com.my/forgot-password';
  let hrefValueProject = 'https://project.ifmtnbes.com.my/forgot-password';



  if (AP === 'asset') {
    hrefValue = hrefValueAsset;
  }
  else {
    hrefValue = hrefValueProject;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    if (!username || username === "" || !password || password === "") {
      // { displayToast() }
    }
    else {
      e.preventDefault();
      if (AP === 'project') {
        try {
          // Get the iframe element by its ID
          const iframe = document.getElementById("myIframe");
          const wind = iframe.contentWindow
          const getToken = async () => {
            const res = await dispatch(login({ username, password, AP }))
            const data = {
              accessToken: res.payload.access,
              refreshToken: res.payload.refresh,
            }
            wind.postMessage(JSON.stringify(data), "*")
          }
          const answer = await getToken()
          console.log("answer", answer)
          window.location.href = "https://project.ifmtnbes.com.my"
        }
        catch (e) {
          console.log("what went wrong", e)
        }
      }
      if (AP === 'asset') {
        try {
          const iframe = document.getElementById("myIframe-asset");
          const wind = iframe.contentWindow
          const getToken = async () => {
            const res = await dispatch(login({ username, password, AP }))
            const data = {
              access: res.payload.access,
              refresh: res.payload.refresh,
            }
            wind.postMessage(JSON.stringify(data), "*")
          }
          await getToken()
          const userProfile = await dispatch(getUserProfile())
          if (userProfile.payload?.profile?.department_name !== 'TNB ASSET AUDIT') {
            window.location.href = "https://asset.ifmtnbes.com.my"
          } else {
            window.location.href = "https://asset.ifmtnbes.com.my/assets"
          }

        }
        catch (e) {
          console.log("what went wrong", e)
        }
      }
      else {
        console.log("not working")
      }

    }
  };

  return (
    <div
      style={{
        background:
          "radial-gradient(98.73% 98.73% at 50% 1.27%, #2392F1 0%, #0060B2 69.1%)",
      }}
      className="loginPage-background"
    >
      <iframe
        title="myIframe"
        src="https://project.ifmtnbes.com.my/login"
        width="800"
        height="600"
        id="myIframe"
        frameBorder="0"
        style={{ display: 'none' }}
      ></iframe>
      <iframe
        title="myIframe-asset"
        src="https://asset.ifmtnbes.com.my/login"
        width="800"
        height="600"
        id="myIframe-asset"
        frameBorder="0"
        style={{ display: 'none' }}
      ></iframe>
      {/* Logo */}
      <div className="login-container">
        <div
          style={{
            marginTop: "50px",
            width: "120px",
            height: "120px",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 36px",
            borderRadius: "18px",
            boxShadow:
              "0px 3px 6px 0px #4545451A, 0px 11px 11px 0px #45454517, 0px 25px 15px 0px #4545450D, 0px 45px 18px 0px #45454503, 0px 71px 20px 0px #45454500",
          }}
        >
          <img
            src="/tnb.png"
            alt="Tnb"
            style={{
              width: "71px",
              height: "81px"
            }}
          />
        </div>
        {/* Caption */}
        <div style={{ marginTop: "30px", textAlign: "center" }}>
          <h1>
            Welcome to FaMES
          </h1>
          <p>
            Facility Management System TNB ES
          </p>
        </div>
        {/* Asset or Project */}
        <div style={{ display: "flex", gap: "24px", marginTop: "42px" }}>
          <FormControlLabel
            sx={{ color: "white" }} // This applies to the root element
            componentsProps={{
              typography: {
                sx: {
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "white",
                  fontFamily: '"Source Sans 3"',
                },
              },
            }}
            value="end"
            control={
              <Radio
                {...controlAP("asset")}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label="Asset"
          />
          <FormControlLabel
            sx={{ color: "white" }} // This applies to the root element
            componentsProps={{
              typography: {
                sx: {
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "white",
                  fontFamily: '"Source Sans 3"',
                },
              },
            }}
            value="end"
            control={
              <Radio
                {...controlAP("project")}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label="Project"
          />
        </div>
        {isError ? (
          <Alert sx={{ mb: 2 }} severity="error">
            Invalid credentials or server error!
          </Alert>
        ) : null}
        {isSuccess ? (
          <Alert sx={{ mb: 2 }} severity="success">
            Successful Login. Hold on tight, redirecting
          </Alert>
        ) : null}
        <div className="inputs-container">
          {/* Username */}
          <div
            style={{
              borderBottom: "1px solid #E8E8E8",
              marginTop: "28px",
              paddingLeft: "22px",
              paddingRight: "22px",
            }}
          >
            <TextField
              id="input-with-icon-textfield"
              placeholder="Username"
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlinedIcon sx={{ color: "white" }} />
                  </InputAdornment>
                ),
                style: {
                  color: "#ffffff", // Input text color
                  borderBottom: "none", // Remove default border from TextField
                  maxWidth: "100%"
                },
                inputProps: {
                  style: {
                    color: "#ffffff", // Input text color
                    opacity: 1, // Opacity for placeholder text
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#ffffff", // Label color
                  fontFamily: '"Source Sans 3"',
                },
              }}
              focused={true} // Manually focus the input to ensure it behaves correctly on focus
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1, // Change opacity of the placeholder
                },
              }}
              onChange={(e) => setUsername(e.target.value)}
              value={username}
            />
          </div>
          {/* Password */}
          <div
            style={{
              borderBottom: "1px solid #E8E8E8",
              marginTop: "20px",
              paddingLeft: "22px",
              paddingRight: "22px",
            }}
          >
            <TextField
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              fullWidth
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon
                      sx={{
                        color: "white",
                        marginRight: "3px",
                        marginLeft: "1.8px",
                      }}
                      fontSize="small"
                    />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: "white" }} fontSize="small" />
                      ) : (
                        <Visibility sx={{ color: "white" }} fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                style: {
                  color: "#ffffff", // Input text color
                  borderBottom: "none", // Remove default border from TextField
                },
                inputProps: {
                  style: {
                    color: "#ffffff", // Input text color
                    opacity: 1, // Opacity for placeholder text
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#ffffff", // Label color
                  fontFamily: '"Source Sans 3"', // Font family for the label
                },
              }}
              focused={true} // Manually focus the input to ensure it behaves correctly on focus
              sx={{
                "& .MuiInputBase-input::placeholder": {
                  opacity: 1, // Change opacity of the placeholder
                },
              }}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
          </div>
          <Button
            variant="contained"
            disableElevation
            sx={{
              textTransform: "none",
              fontFamily: '"Source Sans 3"',
              backgroundColor: "#669ECD",
              color: "white",
              fontWeight: "600",
              fontSize: "16px",
              width: "100%",
              marginTop: "32px",
              "&:hover": {
                // backgroundColor: "#79aedb",
                backgroundColor: "#4d87b8",
              },
              padding: "12px 0",
              borderRadius: "6px",
            }}
            onClick={handleSubmit}
          >
            {isLoading ? <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box> : "Log In"}
          </Button>
        </div>
        {/* Forgot Password */}
        <div style={{ marginTop: "16px" }}>
          <div style={{ color: "white", fontWeight: "400", fontSize: "16px" }}>
            Forgot Password?{" "}
            <a href={hrefValue} style={{ fontWeight: "600", textDecoration: "underline", color: "white" }}>
              Reset password
            </a>
          </div>
        </div>
        {/* Copyright */}
        <div style={{ marginTop: "60px", textAlign: "center" }}>
          <div style={{ color: "white", fontWeight: "400", fontSize: "16px" }}>
            Powered by FOX
          </div>
          <a href="https://www.infinitywave.io/" target="_blank"  rel="noopener noreferrer">
            <div
              style={{
                color: "white",
                fontWeight: "400",
                fontSize: "16px",
                marginTop: "8px",
                textDecoration: "underline",
                marginBottom: "30px"
              }}
            >
              &copy; Infinity Wave {new Date().getFullYear()}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
